import React from 'react';
import { RouteComponentProps, Link } from "react-router-dom";

import Entry from "./Entry";
import Entries from "../data/entries.json";
import GioEntry, {GetGioUrl} from "../types/gioentry";

export default function FetchProject(props: RouteComponentProps) {
  document.title = 'Grandpa Gio and The Residents';
  const page = Math.max(1, parseInt((props.match.params as any)["page"] ?? 1));
  const index = (page - 1) * 3;
  const entries = Entries.entries.slice(index, index + 3).map((entry) => {
    return <Entry entry={entry} key={entry.project} />;
  });

  let navNext;
  let navPrev;

  if (page > 1) {
    const pageLink = `/page/${page - 1}`;
    navNext = (
      <div className="nav-next">
        <Link to={pageLink}>Newer posts <span className="meta-nav">→</span></Link>
      </div>
    );
  }

  if (Entries.entries.length > index + 3) {
    const pageLink = `/page/${page + 1}`;
    navPrev = (
      <div className="nav-previous">
        <Link to={pageLink}><span className="meta-nav">←</span> Older posts</Link>
      </div>
    );
  }

  return (
    <>
      {entries}
      <nav id="nav-below" className="navigation" role="navigation">
        <h3 className="assistive-text">Post navigation</h3>
        {navPrev}
        {navNext}
      </nav>
    </>
  )
}
