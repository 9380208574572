export default interface GioEntry {
  title: string;
  project: string;
  date: string;
  alttext: string;
  image: string;
  html: string;
}

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export function GetGioUrl(entry: GioEntry): string {
  return entry.project.replace('&', 'and').replace(/[^a-zA-Z0-9\-\s]/g, '').replace(/\s+/g, '-').toLocaleLowerCase();
}

export function GetGioDateString(entry: GioEntry): string {
  const pieces = entry.date.split('-');

  const year = pieces[0];
  const month = months[parseInt(pieces[1], 10) - 1];
  const day = parseInt(pieces[2], 10);
  return `${month} ${day}, ${year}`;
}
