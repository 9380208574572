import React from 'react';
import {Link} from 'react-router-dom';
import GioEntry, {GetGioUrl, GetGioDateString} from "../types/gioentry";

interface EntryProps {
  entry: GioEntry;
}

export default function Entry(props: EntryProps) {
  const imgUrl = `/images/${props.entry.image}`;
  return (
    <article className="post type-post status-publish format-standard hentry category-residents">
      <header className="entry-header">
        <h1 className="entry-title">
          <Link to={'/' + GetGioUrl(props.entry)} rel="bookmark">{props.entry.title}</Link>
        </h1>
      </header>
      <div className="entry-content">
        <a href={imgUrl} target="_blank">
          <img className="alignright wp-image-60 size-medium" title={props.entry.alttext} src={imgUrl} style={{maxHeight: '300px'}} />
        </a>
        <div dangerouslySetInnerHTML={{__html: props.entry.html}} />
      </div>
      <footer className="entry-meta">This entry was posted on <a href={'/' + GetGioUrl(props.entry)}>{GetGioDateString(props.entry)}</a>.</footer>
    </article>
  )
}
