import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link } from "react-router-dom";

import Entry from "./Entry";
import Entries from "../data/entries.json";
import Extras from "../data/extras.json";
import GioEntry, {GetGioUrl} from "../types/gioentry";

export default function FetchProject(props: RouteComponentProps) {
  const [title, setTitle] = useState<string>("Grandpa Gio and The Residents");
  const project = (props.match.params as any)["project"].toLocaleLowerCase();
  const extra = Extras.extras.find((e) => {
    if (GetGioUrl(e) === project) {
      return true;
    }
  });
  if (extra) {
    document.title = extra.title + ' | Grandpa Gio and The Residents';
    return <Entry entry={extra} />;
  }

  const entry = Entries.entries.find((e) => {
    if (GetGioUrl(e) === project) {
      return true;
    }
  });
  if (entry) {
    const index = Entries.entries.indexOf(entry);

    let navNext;
    let navPrev;

    if (index > 0) {
      const pageLink = GetGioUrl(Entries.entries[index - 1]);
      navNext = (
        <div className="nav-next">
          <Link to={pageLink}>{Entries.entries[index - 1].title} <span className="meta-nav">→</span></Link>
        </div>
      );
    }

    if (index + 1 < Entries.entries.length) {
      const pageLink = GetGioUrl(Entries.entries[index + 1]);
      navPrev = (
        <div className="nav-previous">
          <Link to={pageLink}><span className="meta-nav">←</span> {Entries.entries[index + 1].title}</Link>
        </div>
      );
    }



    document.title = entry.title + ' | Grandpa Gio and The Residents';
    return (
      <>
        <Entry entry={entry} />
        <nav id="nav-below" className="navigation" role="navigation">
          <h3 className="assistive-text">Post navigation</h3>
          {navPrev}
          {navNext}
        </nav>
      </>
    );
  } else {
    return (
      <div>Do not forget my face, my friend. ;o</div>
    );
  }
}
