import React from 'react';

import Header from "./Header";
import SidebarEntry from "./SidebarEntry";
import Entries from "../data/entries.json";

interface PageProps {
  children: React.ReactNode;
}

function Page(props: PageProps) {
  const recentEntries = Entries.entries.map((e) => {
    return (
      <li><SidebarEntry entry={e} key={e.project} /></li>
    );
  });
  return (
    <div id="page" className="hfeed site">
      <Header />
      <div id="main" className="wrapper">
        <div id="primary" className="site-content">
          <div id="content" role="main">
            {props.children}
          </div>
        </div>
        <div id="secondary" className="widget-area" role="complementary">
          <aside id="recent-posts-2" className="widget widget_recent_entries">
            <h3 className="widget-title">All Entries</h3>
            <ul>
              {recentEntries}
            </ul>
          </aside>
          <aside id="text-2" className="widget widget_text">
            <h3 className="widget-title">Residents Sites</h3>
            <div className="textwidget">
              <p>
                <a href="http://www.residents.com/">The Residents Official Web Site</a><br />
                <a href="https://www.facebook.com/TheOfficialResidents">Official Facebook Page</a><br />
                <a href="https://www.twitter.com/TheResidents">Official Twitter Feed</a>
              </p>
              <p><a href="https://web.archive.org/web/20201202175059/https://residentsmovie.com/">Theory Of Obscurity: A Film About The Residents <em>(archived version)</em></a></p>
              <p><a href="https://www.homeageconversations.com/" style={{opacity: 0}} title=";)">&nbsp;</a></p>
            </div>
          </aside>
        </div>
        <footer id="colophon" role="contentinfo">
          <div className="site-info">
            Thank you to Grandpa Gio
          </div>
        </footer>
      </div>

    </div>
  );
}

export default Page;
