import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Page from './components/Page';
import Entry from './components/Entry';
import Scroller from './components/Scroller';
import FetchProject from "./components/FetchProject";
import EntriesSlice from "./components/EntriesSlice";

import Entries from './data/entries.json';

function App() {
  return (
    <Router>
      <Scroller />
      <Page>
        <Switch>
          <Route exact path="/" component={EntriesSlice} />
          <Route path="/page/:page" component={EntriesSlice} />
          <Route path="/:project" component={FetchProject} />
          <Route>
            :)
          </Route>
        </Switch>
      </Page>
    </Router>
  );
}

export default App;
