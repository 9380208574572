import React from 'react';
import {Link} from 'react-router-dom';

import GioEntry, {GetGioUrl, GetGioDateString} from "../types/gioentry";

interface EntryProps {
  entry: GioEntry;
}

export default function SidebarEntry(props: EntryProps) {
  const postDate = GetGioDateString(props.entry);
  return (
    <>
      <Link to={'/' + GetGioUrl(props.entry)}>{props.entry.title}</Link>
      <span className="post-date">{postDate}</span>
    </>
  )
}
